<template>
  <el-dialog
    fullscreen
    append-to-body
    :visible.sync="currentShowFlag"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @open="handleDialogOpen"
    @closed="handleDialogClosed"
  >
    <div
      slot="title"
      class="detail-dialog__header"
    >
      {{ $t('config_group.detailDialog') }}
    </div>

    <simple-form
      ref="form"
      v-model="formModel"
      label-width="80px"
      :form-field="formField"
      :view-flag="viewFlag"
    >
      <template slot="agency">
        <el-button
          v-if="!viewFlag"
          type="primary"
          @click="isShowAgencyPicker=true"
        >
          {{ $t('validate.notSelect') }}
        </el-button>
        <el-button
          v-if="!viewFlag"
          @click="agencyData=[]"
        >
          {{ $t('operation.clear') }}
        </el-button>
      </template>
    </simple-form>

    <!-- 经销商列表 -->
    <div style="height:260px;">
      <simple-table
        style="height:100%;margin:0 16px;"
        :table-column="tableColumn"
        :table-data="agencyData"
        :with-pagination="false"
      >
        <template
          slot="region"
          slot-scope="scope"
        >
          {{ $getDictLabel({type: 'sys_office_region', value: scope.row.region}) }}
        </template>
        <template
          slot="operation"
          slot-scope="scope"
        >
          <el-button
            circle
            icon="el-icon-delete"
            :disabled="viewFlag"
            :title="$t('operation.delete')"
            @click="handleDeleteClick(scope.$index, 1)"
          />
        </template>
      </simple-table>
    </div>
    <agency-picker
      :show-flag.sync="isShowAgencyPicker"
      :selected-data="agencyData"
      @confirm="handleAgencyConfirm"
    />

    <!-- KPI Tabs -->
    <div
      class="title"
      style="margin:20px 0;"
    >
      {{ $t('config_group.kpi') }}
    </div>
    <kpi-tabs
      :active-tab.sync="activeName"
      :kpi-data.sync="kpiData"
      :detail-data="detailData"
      :view-flag="viewFlag"
    />

    <!-- Footer -->
    <div slot="footer">
      <el-button @click="handleDialogClose">
        {{ viewFlag ? $t('operation.close') : $t('operation.cancel') }}
      </el-button>
      <el-button
        v-if="!viewFlag"
        type="primary"
        @click="handleDataSubmit"
      >
        {{ $t('operation.submit') }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import kpiTabs from './kpiTabs'
import agencyPicker from './agencyPicker'

const BASEURL = {
  update: '/jlr/mt/group/update',
  get: '/jlr/mt/group/get',
  save: '/jlr/mt/group/save'
}
export default {
  name: 'ConfigGroupDetail',
  components: { agencyPicker, kpiTabs },
  props: {
    showFlag: { type: Boolean, default: false },
    viewFlag: { type: Boolean },
    copyFlag: { type: Boolean, default: false },
    detailData: { type: Object }
  },
  data () {
    return {
      activeName: '1',
      formModel: {},
      kpiData: [],
      agencyData: [],
      isShowAgencyPicker: false
    }
  },
  inject: ['handleDataSearch'],
  computed: {
    currentShowFlag: {
      get () { return this.showFlag },
      set (val) { this.$emit('update:showFlag', val) }
    },
    formField () {
      return [
        {
          prop: 'name',
          type: 'Input',
          label: this.$t('config_group.name'),
          rules: { trigger: 'blur', message: this.$t('validate.notBlank'), required: true }
        },
        {
          prop: 'sort',
          type: 'NumberInput',
          label: this.$t('config_group.sort'),
          component: { min: 0 },
          rules: { trigger: 'blur', message: this.$t('validate.notBlank'), required: true }
        },
        {
          label: this.$t('config_group.agency'),
          slotName: 'agency',
          col: { xs: 24, sm: 24, md: 24 }
        }
      ]
    },
    tableColumn () {
      return [
        { prop: 'name', label: this.$t('system_office.name'), minWidth: 160 },
        { prop: 'code', label: this.$t('system_office.code'), minWidth: 120 },
        { prop: 'region', label: this.$t('system_office.region'), minWidth: 120, slotName: 'region' },
        { prop: 'parent.name', label: this.$t('config_group.bloc'), minWidth: 120 },
        {
          label: this.$t('field.operation'),
          slotName: 'operation',
          width: 80,
          fixed: 'right'
        }
      ]
    }
  },
  methods: {
    handleDialogClose () {
      this.currentShowFlag = false
    },
    handleAgencyConfirm (selections) {
      // this.$set(this, 'agencyData', [])
      selections.forEach(selection => {
        const result = this.agencyData.find(data => { return selection.id === data.id })
        if (!result) this.agencyData.push(selection)
      })
    },
    handleDeleteClick (index) {
      this.agencyData.splice(index, 1)
    },
    handleDataSubmit () {
      this.$refs.form.$refs.simpleForm.validate(status => {
        if (status) {
          const loadingFlag = this.$loading({ target: this.$el.querySelector('.el-dialog') })
          const submitModel = this.$_.cloneDeep(this.formModel)
          submitModel.itemList = []
          this.kpiData.forEach(item => { submitModel.itemList.push(item) })

          submitModel.dealerList = []
          this.agencyData.forEach(item => { submitModel.dealerList.push({ sysOffice: item }) })

          this.$axios
            .post(this.detailData.id ? BASEURL.update : BASEURL.save, submitModel)
            .then(resp => {
              this.currentShowFlag = false
              this.$message({
                type: 'success',
                message: this.$t('tip.saveSuccess')
              })
              this.handleDataSearch()
            })
            .finally(() => {
              loadingFlag.close()
            })
        }
      })
    },
    handleDialogOpen () {
      this.activeName = '1'
      if (!this.detailData.id) {
        this.kpiData = []
        this.agencyData = []
        return
      }
      const loadingFlag = this.$loading({ target: this.$el.querySelector('.el-dialog') })
      this.$axios.post(BASEURL.get, { id: this.detailData.id })
        .then(resp => {
          const respData = resp.data
          if (!this.copyFlag) {
            this.formModel = respData
            this.agencyData = []
            respData.dealerList.forEach(ele => {
              this.agencyData.push(ele.sysOffice)
            })
          } else {
            delete this.detailData.id
          }
          this.kpiData = respData.itemList
        })
        .finally(() => {
          loadingFlag.close()
        })
    },
    handleDialogClosed () {
      this.formModel = {}
      this.kpiData = []
      this.agencyData = []
      this.$refs.form.resetFields()
    }
  }
}
</script>
<style lang="less" scoped>
.title {
  width: 100%;
  padding: 10px;
  border-bottom: 2px solid #eee;
}
</style>
