<template>
  <el-dialog
    :visible.sync="currentShowFlag"
    top="10px"
    append-to-body
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @open="handleDialogOpen"
    @closed="handleDialogClosed"
  >
    <div
      slot="title"
      class="detail-dialog__header"
    >
      {{ $t('config_group.agency') }}
    </div>
    <div style="height:500px;">
      <simple-table
        ref="kpiTable"
        with-selection
        :table-column="tableColumn"
        :table-data="tableData"
        :with-pagination="false"
        @selection-change="handleSelectionChange"
      >
        <simple-form
          v-model="searchModel"
          :form-field="searchFormField"
        >
          <template slot="tableOperation">
            <el-button
              type="primary"
              @click="handleDataSearch"
            >
              {{ $t('operation.search') }}
            </el-button>
          </template>
        </simple-form>
        <template
          slot="region"
          slot-scope="scope"
        >
          {{ $getDictLabel({type: 'sys_office_region', value: scope.row.region}) }}
        </template>
      </simple-table>
    </div>
    <div slot="footer">
      <el-button @click="handleDialogClosed">
        {{ $t('operation.cancel') }}
      </el-button>
      <el-button
        type="primary"
        @click="handleDataConfirm"
      >
        {{ $t('operation.confirm') }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import pageMixins from '@/components/base/simple-table/mixin'
const BASEURL = {
  list: '/system/office/list'
}
export default {
  name: 'ConfigKpiPicker',
  mixins: [pageMixins],
  props: {
    showFlag: { type: Boolean, default: false },
    selectedData: { type: Array }
  },
  data () {
    return {
      formModel: {},
      searchModel: { category: 'sys_office_category_3' },
      tableData: [],
      selectedItems: [],
      tempItems: [],
      groupList: [],
      gridOptions: {},
      groupLoading: false
    }
  },
  computed: {
    currentShowFlag: {
      get () { return this.showFlag },
      set (val) { this.$emit('update:showFlag', val) }
    },
    selections: {
      get () { return this.selectedData },
      set (val) { this.$emit('update:selectedData', val) }
    },
    searchFormField () {
      return [
        {
          prop: 'name',
          label: this.$t('system_office.name'),
          type: 'Input',
          col: { xs: 8, sm: 8, md: 8 },
          component: { clearable: true }
        },
        {
          prop: 'code',
          label: this.$t('system_office.code'),
          type: 'Input',
          col: { xs: 8, sm: 8, md: 8 },
          component: { clearable: true }
        },
        {
          prop: 'region',
          type: 'Select',
          col: { xs: 8, sm: 8, md: 8 },
          component: { optionList: this.$getDictList('sys_office_region'), clearable: true },
          label: this.$t('dealer_finance.area')
        },
        {
          prop: 'dealerParent',
          type: 'SearchableInput',
          col: { xs: 8, sm: 8, md: 8 },
          label: this.$t('dealer_finance.group'),
          component: {
            remoteMethod: this.getGroupList,
            loading: this.groupLoading,
            optionList: this.groupList,
            valueKey: 'id',
            clearable: true
          },
          event: {
            focus: () => {
              this.getGroupList()
            }
          }
        },
        {
          slotName: 'tableOperation',
          labelWidth: '0',
          style: { textAlign: 'right' },
          col: { xs: 16, sm: 16, md: 16 }
        }
      ]
    },
    tableColumn () {
      return [
        { prop: 'name', label: this.$t('system_office.name'), minWidth: 260 },
        { prop: 'code', label: this.$t('system_office.code'), minWidth: 120 },
        { prop: 'region', label: this.$t('system_office.region'), minWidth: 120, slotName: 'region' },
        { prop: 'parent.name', label: this.$t('config_group.bloc'), minWidth: 120 }
      ]
    }
  },
  methods: {
    handleDataSearch () {
      const searchInfo = this.$utils.parseQueryCondition(this.searchModel, this.searchFormField)
      const params = Object.assign({}, { page: { orderBy: 'sort', pageSize: -1 } }, searchInfo)
      const loadingFlag = this.$loading({ target: this.$el.querySelector('.el-dialog') })
      if (this.searchModel.dealerParent) {
        params.filter.push({ left: 'parent.id', operate: '=', right: this.searchModel.dealerParent.id })
      }
      this.$axios
        .post(BASEURL.list, params)
        .then(resp => {
          const respData = resp.data
          this.tableData = respData.list
          // if (this.tableData.length) {
          //   this.$nextTick(() => {
          //     // 反选 selectedData
          //     if (this.selectedData.length) {
          //       this.selectedData.forEach(obj => {
          //         const newRow = this.tableData.find(ele => { return ele.id === obj.id })
          //         if (newRow) this.$refs.kpiTable.toggleRowSelection(newRow, true)
          //       })
          //     }
          //     // 按条件查询之前，存储之前已勾选的 items，否则会被查询结果冲掉
          //     this.tempItems = this.selectedItems
          //     // 反选 tempItems
          //     if (this.tempItems.length) {
          //       this.tempItems.forEach(obj => {
          //         const newRow = this.tableData.find(ele => { return ele.id === obj.id })
          //         if (newRow) this.$refs.kpiTable.toggleRowSelection(newRow, true)
          //       })
          //     }
          //   })
          // }
        })
        .finally(() => {
          loadingFlag.close()
        })
    },
    handleSelectionChange (selections, obj) {
      // 如果取消勾选，需手动删除 tempItems 里的对应项
      // const result = selections.find(ele => { return ele.id === obj.id })
      // if (!result) {
      //   this.tempItems = this.tempItems.filter(ele => { return ele.id !== obj.id })
      // }
      // 合并 tempItems 和当前选项
      // this.selectedItems = this.tempItems.concat(selections)
      this.selectedItems = selections
    },
    handleDialogClosed () {
      this.searchModel = { category: 'sys_office_category_3' }
      this.$refs.kpiTable.clearSelection()
      this.currentShowFlag = false
    },
    handleDataConfirm () {
      this.$emit('confirm', this.selectedItems)
      this.handleDialogClosed()
    },
    handleDialogOpen () {
      this.handleDataSearch()
    },
    getGroupList (name) {
      this.groupLoading = true
      const params = {
        filter: [{ left: 'category', operate: '=', right: 'sys_office_category_2' }]
      }
      if (name) {
        params.filter.push({ left: 'name', operate: 'like', right: name })
      }
      this.$axios
        .post(BASEURL.list, params)
        .then(resp => {
          const respData = resp.data
          this.groupList = respData.list.map(item => {
            return { key: item.id, value: item, label: item.name }
          })
        })
        .finally(() => {
          this.groupLoading = false
        })
    }
  }
}
</script>
